<template lang="pug">
.q-px-lg.q-py-md

  .q-mb-lg
    .text-grey-8 WebRTC를 이용하여 chatting 을 하는 페이지 입니다.

  q-tabs(v-model="tab" align="justify")
    q-tab(name="chat" label="Chat")
    q-tab(name="debug" label="Debug")
  q-separator

  q-tab-panels(v-model="tab")

    //- CHAT
    q-tab-panel(name="chat")
      .q-gutter-y-md
      
        q-input(
          label="Session ID (click me to start)"
          stack-label filled readonly
          @click="onClickSession"
          v-model="sessionID")
      
        .bg-grey-3
          .scroll(style="height: 300px; white-space: break-spaces;" ref="chat-box")
            | {{chat}}
              
        q-input(
          v-model="message"
          @keyup.enter="onKeyupEnter"
          placeholder="enter a message and hit 'enter' to send"
          filled)

    //- DEBUG
    q-tab-panel(name="debug")
      .q-gutter-y-md

        q-markup-table(separator="cell" flat bordered)
          thead
            tr
              th Type
              th Address
              th Related Address
          tbody
            tr(v-for="(v, i) in offerCandidates")
              td(v-if="i === 0" :rowspan="offerCandidates.length") Offer
              td {{`${v.protocol}://${v.ip}:${v.port}`}}
              td {{v.relatedAddress ? `${v.protocol}://${v.relatedAddress}:${v.relatedPort}` : '-'}}
            tr(v-for="(v, i) in answerCandidates")
              td(v-if="i === 0" :rowspan="answerCandidates.length") Answer
              td {{v.protocol}}://{{v.ip}}:{{v.port}}
              td {{v.relatedAddress ? `${v.protocol}://${v.relatedAddress}:${v.relatedPort}` : '-'}}
      
        .bg-grey-3
          .scroll(style="height: 200px; white-space: break-spaces;")
            | {{offer}}
      
        .bg-grey-3
          .scroll(style="height: 200px; white-space: break-spaces;")
            | {{answer}}

</template>
<script>
import firebase from "firebase/app";
import Peer from "simple-peer"
export default {
  computed: {
  },
  data: function() {
    return {
      sessionID: '',
      initiator: false,
      p: null,
      myPeer: '',
      message: '',
      chat: '',
      remoteSignal: null,
      listeningUpdates$unsubscribe: null,
      // 
      tab: 'chat',
      offer: '',
      offerCandidates: [],
      answer: '',
      answerCandidates: [],
    }
  },
  watch: {
    chat: function () {
      const ref = this.$refs['chat-box']
      if (!ref) return
      setTimeout(() => ref.scrollTop = ref.scrollHeight, 100)
    },
    offer: function () {
      if (!this.offer) this.offerCandidates = []
      this.offer.split("\n").forEach(v => {
        if (!v.includes("a=candidate:")) return
        this.offerCandidates.push(this.$tool.parseICECandidate(v))
      })
    },
    answer: function () {
      if (!this.answer) this.answerCandidates = []
      this.answer.split("\n").forEach(v => {
        if (!v.includes("a=candidate:")) return
        this.answerCandidates.push(this.$tool.parseICECandidate(v))
      })
    },
  },
  mounted: function () {
  },
  beforeUnmount: function() {
    this.stopListeningUpdates()
  },
  methods:{
    onKeyupEnter: function () {
      const message = `${this.myPeer}: ${this.message}`
      if (!this.p) return
      this.p.send(this.message || " ")
      this.message = ""
      this.appendToChat(message)
    },
    onClickSession: function () {
      if (this.sessionID) return this.openResetDialog()
      this.openChooseOptionDialog()
    },
    openResetDialog: function () {
      if (!confirm("Do you want to reset?")) return
      this.reset()
    },
    openChooseOptionDialog: function () {
      this.$q.dialog({
        title: 'Choose an option:',
        options: {
          type: 'radio',
          model: 'join',
          items: [
            { label: 'JOIN an exising session', value: 'join' },
            { label: 'CREATE a new Session', value: 'new' },
          ]
        },
        cancel: true,
      }).onOk(data => {
        switch (data) {
          case 'join':
            this.openJoinSessionDialog()
            break
          case 'new':
            this.sessionID = this.$tool.generateSessionID()
            this.init(true)
            break
        }
      })
    },
    openJoinSessionDialog: function () {
      this.$q.dialog({
        title: 'Join Session',
        message: 'Enter Session ID to join.',
        prompt: {
          model: '',
          isValid: val => val.length < 8,
        },
        cancel: true,
      }).onOk(data => {
        this.sessionID = data
        this.init(false)
      })
    },
    init: async function (initiator) {
      // INIT session
      let partnerPeer
      this.initiator = initiator
      switch (initiator) {
        case true:
          this.myPeer = 'peer0'
          partnerPeer = 'peer1'
          await this.createSession()
          break
        case false:
          this.myPeer = 'peer1'
          partnerPeer = 'peer0'
          break
      }

      // INIT peer
      this.p = new Peer({
        initiator,
        trickle: false,
      })
      const p = this.p

      // Signaling
      p.on('signal', data => {
        this.appendToChat(`[GOT-SIGNAL] ${new Date().toISOString()}`)
        // CASE 1 - initiator
        if (initiator) {
          this.updateSessionOffer(data)
          return
        }
        // CASE 2 - participant
        this.updateSessionAnswer(data)
      })
      // Event handlers
      p.on('error', err => this.appendToChat(`[ERROR] ${err.message}`))
      p.on('connect', () => this.appendToChat(`[CONNECTED] ${new Date().toISOString()}`))
      p.on('close', () => this.appendToChat(`[CLOSE] ${new Date().toISOString()}`))
      p.on('data', data => this.appendToChat(`${partnerPeer}: ${data}`))

      // CASE - participant
      if (!initiator) setTimeout(() => this.getSession(), 1000)
    },
    reset: function () {
      if (this.p instanceof Object) this.p.destroy()
      this.sessionID = ''
      this.initiator = false
      this.p = null
      this.myPeer = ''
      this.message = ''
      this.chat = ''
      this.remoteSignal = null
      this.stopListeningUpdates()
      // 
      this.tab = 'chat'
      this.offer = ''
      this.offerCandidates = []
      this.answer = ''
      this.answerCandidates = []
    },
    setRemoteSignal: function (signal) {
      this.appendToChat(`[SET-SIGNAL] ${new Date().toISOString()}`)
      switch (this.initiator) {
        case true:
          this.answer = signal.sdp
          break
        case false:
          this.offer = signal.sdp
          break
      }
      return this.p.signal(signal)
    },
    appendToChat: function (message) {
      this.chat = this.chat ? `${this.chat}\n${message}` : message
    },
    //
    createSession: function() {
      if (!this.sessionID) return
      this.startListeningUpdates()
      const ref = this.$db.collection('sessions').doc(this.sessionID)
      return ref.set({
        initiator: "peer0",
        state: "gather_candidates",
        created_at: firebase.firestore.FieldValue.serverTimestamp(),
        updated_at: firebase.firestore.FieldValue.serverTimestamp(),
      })
    },
    updateSessionOffer: function(offer) {
      if (!this.sessionID) return
      this.offer = offer.sdp
      const ref = this.$db.collection('sessions').doc(this.sessionID)
      return ref.update({
        state: "signaling",
        by_peer0_offer: offer,
        updated_at: firebase.firestore.FieldValue.serverTimestamp(),
      })
    },
    updateSessionAnswer: function(answer) {
      if (!this.sessionID) return
      this.answer = answer.sdp
      const ref = this.$db.collection('sessions').doc(this.sessionID)
      return ref.update({
        state: "signaling",
        by_peer0_answer: answer,
        updated_at: firebase.firestore.FieldValue.serverTimestamp(),
      })
    },
    getSession: async function() {
      if (!this.sessionID) return
      this.startListeningUpdates()
      const ref = this.$db.collection('sessions').doc(this.sessionID)
      const doc = await ref.get()
      if (!doc.exists) throw new Error("the session doesn't exist")
      return this.onSessionChange(doc.data())
    },
    onSessionChange: function (session) {
      let signal
      switch (this.myPeer) {
        case "peer0":
          signal = session.by_peer0_answer
          break
        case "peer1":
          signal = session.by_peer0_offer
          break
      }
      if (!signal) return
      if (this.remoteSignal) return
      this.remoteSignal = signal
      return this.setRemoteSignal(signal)
    },
    startListeningUpdates: function() {
      this.stopListeningUpdates()
      const ref = this.$db.collection('sessions').doc(this.sessionID)
      this.listeningUpdates$unsubscribe = ref.onSnapshot(async (doc) => {
        if (doc.metadata.fromCache) return
        this.onSessionChange(doc.data())
      })
    },
    stopListeningUpdates: function() {
      if (!this.listeningUpdates$unsubscribe) return
      this.listeningUpdates$unsubscribe()
      this.listeningUpdates$unsubscribe = null
    },
  },
}
</script>